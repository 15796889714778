/* eslint-disable complexity */
import {Typography, ActionButton, timeFormatter} from '@hconnect/uikit'
import {Box, Divider} from '@material-ui/core'
import {TableCell, TableRow, Hidden} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import React, {useEffect, useRef} from 'react'
import {useFormContext} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import DeleteIcon from '@mui/icons-material/Delete'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'

import {
  cutOffTimeDataCellPadding,
  defaultTableCellPadding,
  useOrderIntakeStyles
} from '../../../Hooks/OrderIntake/useOrderIntakeStyles'
import {InfoTextWithIcon} from '../../../Molecules/InfoTextWithIcon'
import {useFeaturesState} from '../../../Organisms/Features'
import {
  mergeDateRanges,
  getTodaysCutOffTime,
  getMaterialTableRowStyleByStatus
} from '../../../Organisms/OrderIntake/utils'
import {BulkOrderFormValues} from '../../BulkOrdersFormValues'
import {TimeLeftToOrder} from '../../components/TimeLeftToOrder'
import {BUSINESS_HOURS_CUT_OFF_TIME_THRESHOLD} from '../../declarations/constants'
import {MaterialFormStatus} from '../../declarations/OrderIntake.enums'
import {
  OrderIntakeMaterialOptionPayload,
  ActiveMaterialDeliveryItem,
  QuantityType,
  OrderIntakeOption,
  OrderRequest
} from '../../declarations/types'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import moment from 'moment'
import {ExternalChangeWarning, HaulierInfo} from '../../components/VisualComponents'
import {getValidAndInvalidMaterialsForCollect} from '../utils'
import {EvoZeroBadge} from '../../../Molecules/EvoZeroBadge'

interface Props {
  defaultMaterialOption: OrderIntakeMaterialOptionPayload
  selectedSite: OrderIntakeOption
  orderRequest?: OrderRequest
  orderIndex: number
  status: MaterialFormStatus
  isSuccess: boolean
  removeCollection?: () => void
  onActivate?: (item: ActiveMaterialDeliveryItem) => void
}

export const CollectMaterialForm: React.FC<Props> = ({
  defaultMaterialOption,
  selectedSite,
  orderRequest,
  orderIndex,
  status,
  isSuccess,
  removeCollection,
  onActivate
}) => {
  const classes = useOrderIntakeStyles()
  const {
    t,
    i18n: {language}
  } = useTranslation()

  const {getFeature} = useFeaturesState()

  const {watch, setValue} = useFormContext<BulkOrderFormValues>()
  const [showCutOffTime, setShowCutOffTime] = React.useState<boolean>(false)
  const ref = useRef<HTMLDivElement>(null)

  //mobile menu
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const selectedMaterial = watch(`selectedMaterial.${orderIndex}`) ?? defaultMaterialOption
  const resolvedOrderRequest = orderRequest ?? watch(`orders.${orderIndex}`) ?? {}

  useEffect(() => {
    if (isSuccess && ref && ref.current) ref.current.scrollIntoView({block: 'center'})
  }, [isSuccess])

  if (!resolvedOrderRequest?.payload) return null

  const deliveryDateRange = mergeDateRanges(
    (selectedSite.materials[resolvedOrderRequest.payload.materialEnteredNumber] ?? []).map(
      (material) => material.dateRange
    )
  )

  const isTimeForOrderOver = watch('isTimeForOrderOver')
  const shouldFlaggedMaterialsBeFiltered = getFeature('OrderIntakeRemoveIrrelevantMaterials')

  const showHaulierInfo =
    getFeature('OrderIntakeHaulierInformation') ||
    getFeature('OrderIntakeHaulierInformationWithoutPhoneNumber')

  const resolvedMaterialSettings = getValidAndInvalidMaterialsForCollect(
    selectedSite.materials,
    selectedMaterial,
    resolvedOrderRequest.payload.materialEnteredNumber,
    resolvedOrderRequest.payload.deliveryDate,
    shouldFlaggedMaterialsBeFiltered
  )

  const businessHours = defaultMaterialOption.businessHours
  const cutOffTimeThreshold =
    businessHours?.cutOffTimeThreshold ?? BUSINESS_HOURS_CUT_OFF_TIME_THRESHOLD

  const businessDays = defaultMaterialOption.dateRange?.businessDays
    ? defaultMaterialOption.dateRange?.businessDays
    : []
  const todaysCutOffTime = getTodaysCutOffTime(businessDays)[0].cutOffTime
  const cutOffTimeStamp = todaysCutOffTime?.timestamp

  const onTimerActiveHandler = (isTimerActive: boolean) => {
    setShowCutOffTime(isTimerActive)
  }

  const timeToLeftProps = {
    deliveryDateRange,
    cutOffTime: cutOffTimeStamp,
    cutOffTimeThreshold,
    onTimerChange: (isTimeOver: boolean) => setValue('isTimeForOrderOver', isTimeOver),
    deliveryDate: resolvedOrderRequest.payload.deliveryDate,
    timeZone: defaultMaterialOption.businessHours?.timeZone,
    onTimerActive: onTimerActiveHandler
  }

  const handleRemoveCollection = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    event.preventDefault()

    removeCollection && removeCollection()
    handleClose()
  }

  const capacity = defaultMaterialOption.truckCapacity

  const handleMaterialTableRowClick = () => {
    onActivate && onActivate('details')
    handleClose()
  }

  const handleMaterialTableDateCellClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onActivate && onActivate('dateFilter')
    handleClose()
  }

  const handleMaterialTableHaulierCellClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onActivate && onActivate('haulerInfo')
    handleClose()
  }

  const changedDate = resolvedOrderRequest.isDateChange
  const deliveryDate = watch(`orders.${orderIndex}.payload.deliveryDate`)

  return (
    <>
      <Hidden smDown>
        {!isTimeForOrderOver ? (
          <TableRow
            hover
            onClick={handleMaterialTableRowClick}
            style={{cursor: 'pointer', position: 'relative'}}
            className={getMaterialTableRowStyleByStatus(status, isSuccess, classes)}
            data-test-id={`order-request-${orderIndex + 1}`}
          >
            <TableCell
              style={{
                minWidth: 120,
                paddingBottom: showCutOffTime ? cutOffTimeDataCellPadding : defaultTableCellPadding
              }}
              data-column="dateFilter"
              data-test-id={`order-request-date-${orderIndex + 1}`}
              onClick={handleMaterialTableDateCellClick}
            >
              <Typography variant="body2">
                {moment(deliveryDate).locale(language).format('L')}
              </Typography>
              {getFeature('OrderIntakeCollectTimePicker') && (
                <Typography variant="body2">
                  {timeFormatter(
                    moment(resolvedOrderRequest.payload.deliveryTime.earliest, 'HH:mm:ss'),
                    language
                  )}{' '}
                  {timeFormatter(
                    moment(resolvedOrderRequest.payload.deliveryTime.latest, 'HH:mm:ss'),
                    language
                  )}
                </Typography>
              )}
              <Box style={{position: 'absolute', bottom: 2}}>
                <TimeLeftToOrder {...timeToLeftProps} />
              </Box>
              {changedDate && (
                <Box mt={1} data-test-id={`order-request-${orderIndex + 1}-date-changed`}>
                  <ExternalChangeWarning text={t('orderIntake.dateChanged')} />
                </Box>
              )}
            </TableCell>
            <TableCell
              data-column="material"
              data-test-id={`order-request-material-${orderIndex + 1}`}
              style={{
                paddingBottom: showCutOffTime ? cutOffTimeDataCellPadding : defaultTableCellPadding
              }}
            >
              <Box display="flex" flexDirection='column'>
                <EvoZeroBadge
                  isCarbonSavingProduct={
                    resolvedMaterialSettings.materialOption?.material.isCarbonSavingProduct
                  }
                />
                {resolvedMaterialSettings.materialOption?.material.materialDescription}
              </Box>
              <Typography component="div" variant="caption" color="secondary">
                {`${t('orderIntake.plant')} ${resolvedMaterialSettings.materialOption?.plant
                  .plantName}`.toUpperCase()}
              </Typography>
            </TableCell>
            <TableCell
              data-test-id={`order-request-quantity-${orderIndex + 1}`}
              style={{
                paddingBottom: showCutOffTime ? cutOffTimeDataCellPadding : defaultTableCellPadding
              }}
            >
              {defaultMaterialOption.material.quantityType !== QuantityType.Load ? (
                <Typography variant="body2">
                  {watch(`orders.${orderIndex}.payload.capacity.quantity`)?.toFixed(
                    capacity.numberOfDecimals ?? 0
                  )}
                </Typography>
              ) : (
                <Typography variant="body2">
                  {watch(`orders.${orderIndex}.payload.capacity.quantity`)}
                </Typography>
              )}
            </TableCell>
            {showHaulierInfo && (
              <TableCell
                style={{
                  minWidth: 180,
                  paddingBottom: showCutOffTime
                    ? cutOffTimeDataCellPadding
                    : defaultTableCellPadding
                }}
                data-test-id={`order-request-haulier-info-${orderIndex + 1}`}
                onClick={handleMaterialTableHaulierCellClick}
              >
                <HaulierInfo
                  haulierInformation={watch(`orders.${orderIndex}.payload.haulerInfo`)}
                  showTitle={false}
                />
              </TableCell>
            )}
            <TableCell
              style={{
                minWidth: 180,
                maxWidth: 200,
                paddingBottom: showCutOffTime ? cutOffTimeDataCellPadding : defaultTableCellPadding
              }}
              data-test-id={`order-request-driver-instructions-${orderIndex + 1}`}
              title={watch(`orders.${orderIndex}.payload.additionalDriverInfo.driverInstructions`)}
            >
              <Box className={classes.truncatedTextRow}>
                {watch(`orders.${orderIndex}.payload.additionalDriverInfo.driverInstructions`)}
              </Box>
            </TableCell>
            <TableCell
              data-test-id={`order-request-customer-reference-${orderIndex + 1}`}
              style={{
                maxWidth: 180,
                paddingBottom: showCutOffTime ? cutOffTimeDataCellPadding : defaultTableCellPadding
              }}
            >
              <Box className={classes.truncatedTextRow}>
                {watch(`orders.${orderIndex}.payload.customerReference`)}
              </Box>
            </TableCell>
            <TableCell
              style={{
                paddingBottom: showCutOffTime ? cutOffTimeDataCellPadding : defaultTableCellPadding
              }}
            >
              {orderIndex !== 0 ? (
                <Box
                  onClick={(e) => handleRemoveCollection(e)}
                  data-test-id={`delete-card-button-${orderIndex}`}
                >
                  <DeleteIcon color="secondary" />
                </Box>
              ) : null}
            </TableCell>
          </TableRow>
        ) : (
          <Box
            ml={2}
            display="flex"
            flexDirection="row"
            alignItems="center"
            minHeight={50}
            data-test-id={`order-request-${orderIndex + 1}`}
          >
            <Typography
              variant="body2"
              component="div"
              style={{whiteSpace: 'nowrap', marginRight: 10}}
            >
              {watch(`orders.${orderIndex}.payload.deliveryDate`)}
            </Typography>
            <InfoTextWithIcon
              text={t('orderIntake.latestDeliveryTimePassed')}
              data-test-id="order-intake-time-for-order-over"
            />
          </Box>
        )}
      </Hidden>
      <Hidden smUp>
        <Box
          className={classes.mobileMaterialView}
          data-test-id={`order-request-${orderIndex + 1}`}
        >
          <Box>
            <Box onClick={handleClick} className={classes.mobileMaterialViewMenu}>
              <MoreHorizIcon />
            </Box>
            <Menu
              id="material-edit-positioned-menu"
              aria-labelledby="material-edit-positioned-button"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left'
              }}
            >
              <MenuItem onClick={handleMaterialTableRowClick}>
                {t('orderIntake.materialOrder.edit')}
              </MenuItem>
              {orderIndex !== 0 ? (
                <MenuItem onClick={handleRemoveCollection} style={{color: 'red'}}>
                  {t('orderIntake.materialOrder.delete')}
                </MenuItem>
              ) : null}
            </Menu>
          </Box>
          <Box>
            <Typography variant="caption">{t('orderIntake.materialOrder.date')}</Typography>
            <Typography variant="body1" className={classes.mobileMaterialViewText}>
              {watch(`orders.${orderIndex}.payload.deliveryDate`)}{' '}
              {resolvedOrderRequest.payload.deliveryTime.earliest}{' '}
              {resolvedOrderRequest.payload.deliveryTime.latest}
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption">{t('orderIntake.materialOrder.type')}</Typography>
            <Typography variant="body1" className={classes.mobileMaterialViewText}>
              {resolvedMaterialSettings.materialOption?.material.materialDescription}
              <Typography component="div" variant="caption" color="secondary">
                {`${t('orderIntake.plant')} ${resolvedMaterialSettings.materialOption?.plant
                  .plantName}`.toUpperCase()}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography variant="caption">{t('orderIntake.materialOrder.load')}</Typography>
            {defaultMaterialOption.material.quantityType !== QuantityType.Load ? (
              <Typography variant="body1" className={classes.mobileMaterialViewText}>
                {watch(`orders.${orderIndex}.payload.capacity.quantity`)?.toFixed(
                  capacity.numberOfDecimals ?? 0
                )}
              </Typography>
            ) : (
              <Typography variant="body1" className={classes.mobileMaterialViewText}>
                {watch(`orders.${orderIndex}.payload.capacity.quantity`)}
              </Typography>
            )}
          </Box>
          <Box>
            <Typography variant="caption">{t('orderIntake.driverInstructions')}</Typography>
            <Typography variant="body1" className={classes.mobileMaterialViewText}>
              {'-'}
            </Typography>
          </Box>
          <Box marginBottom={2}>
            <Typography variant="caption">{t('orderIntake.materialOrder.po')}</Typography>
            <Typography variant="body1" className={classes.mobileMaterialViewText}>
              {watch(`orders.${orderIndex}.payload.customerReference`)}
            </Typography>
          </Box>
          <Divider />
          <Box marginTop={2}>
            <ActionButton
              icon={<ArrowForwardIcon />}
              style={{justifyContent: 'center'}}
              onClick={handleMaterialTableRowClick}
            >
              {t('orderIntake.materialOrder.viewDetails')}
            </ActionButton>
          </Box>
        </Box>
      </Hidden>
    </>
  )
}
